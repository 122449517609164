<template>
    <div class="page">
        <el-form size="small" :inline="true" ref="searchForm" class="query-form" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-row>
                <el-col :span="4">
                    <el-form-item prop="name">
                        <el-input size="small" maxlength="66" v-model="searchForm.name" placeholder="请输入标引名称" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item prop="state">
                        <el-select v-model="searchForm.state" placeholder="标引状态" clearable>
                            <el-option
                                    v-for="item in $dictUtils.getDictList('label_Index_state')"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item prop="timeScope">
                        <el-date-picker
                                v-model="timeScope"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item>
                        <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                        <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="bg-white top">
            <el-menu :default-active="searchForm.tabIndex" class="el-menu-demo" mode="horizontal" @select="handleSelectTab">
                <el-menu-item index="1">综合</el-menu-item>
                <el-menu-item index="2">审核中</el-menu-item>
            </el-menu>
            <el-table
                    :data="dataList"
                    v-loading = "loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column type="selection" width="50" label="序号"></el-table-column>
                <el-table-column prop="name" label="标引名称" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="标引时间" width="150"></el-table-column>
                <el-table-column prop="userName" label="标引人"></el-table-column>
                <el-table-column prop="state" label="标引状态" >
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("label_Index_state", scope.row.state, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="imgUrl" label="标引档案">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-paperclip" type="text" @click="view(scope.row.id,0)">档案</el-button>
                        <el-button icon="el-icon-paperclip" type="text" @click="view(scope.row.id,1)">清单</el-button>

                    </template>
                </el-table-column>
                <el-table-column label="操作" v-if="searchForm.tabIndex == '2'">
                    <template slot-scope="scope">
                        <el-button  icon="el-icon-s-claim" type="text" size="mini" @click="issue(scope.row.id,1)">通过</el-button>
                        <el-button  icon="el-icon-s-release" type="text" size="mini" @click="issue(scope.row.id,2)">驳回</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!-- 弹窗, 查看 / 修改 -->
        <LabelForm  ref="labelForm" @refreshDataList="refreshList"></LabelForm>
        <LabelForm2  ref="labelForm2" @refreshDataList="refreshList"></LabelForm2>
    </div>
</template>

<script>
    import LabelForm from './LabelForm'
    import LabelForm2 from './LabelForm2'
    export default {
        components: {LabelForm,LabelForm2},
        data() {
            return {
                searchForm: {
                    name: '',
                    startDate:"",
                    endDate:"",
                    state: '',
                    tabIndex:"1"
                },
                inputForm:{
                    id:"",
                    state: '',
                },
                timeScope:[],
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: true,
                dataListSelections: [],
            }
        },
        mounted() {
            this.refreshList();
        },
        methods: {
            search(){
                this.pageNo = 1
                this.refreshList();
            },
            refreshList() {
                // console.log(this.timeScope);
                this.searchForm.startDate = ''
                this.searchForm.endDate = ''
                if(this.timeScope != null && this.timeScope.length > 0){
                    // console.log('选择')
                    this.searchForm.startDate = this.timeScope[0]
                    this.searchForm.endDate = this.timeScope[1]+" 23:59:59"
                }
                // console.log(this.searchForm);
                this.loading = true
                this.$axios(this.api.common.labelIndexList,{
                    ...this.searchForm,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data.status) {
                        this.dataList = data.data.records
                        // console.log(data);
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // tab切换
            handleSelectTab(index) {
                this.searchForm.tabIndex = index;
                this.refreshList()
            },
            // 多选
            selectionChangeHandle (val) {
                this.dataListSelections = val
            },
            // 发布、驳回
            issue(id,state) {
                let pd = "确定通过所选项吗?"
                if(state == 2){
                    pd = "确定驳回所选项吗?"
                }
                this.$confirm(pd, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.id=id
                    this.inputForm.state=state
                    // console.log(this.inputForm);
                    this.loading = true;
                    this.$axios(this.api.common.labelIndexUpdateById, this.inputForm, 'put').then(data => {
                        if(data && data.status){
                            if(state==1){
                                this.$message.success("已通过")
                            }else{
                                this.$message.success("已驳回")
                            }
                            this.loading = false
                            this.refreshList()
                        }else{
                            this.$message.success("异常")
                        }
                    })
                })

            },
            // method 0：新建；1：查看；2：修改
            view(id,type) {
                if(type==0){
                    this.$refs.labelForm.init(id)
                }else{
                    this.$refs.labelForm2.init(id)
                }
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.timeScope = []
                this.search()
            },
        },
    }
</script>

<style scoped>

</style>